.navbar a
{
  color:  #007780;
  text-decoration: none;
  background-color: transparent;
  /* font-weight: 700; */
}

/* below is from from reps */
.navbar
{
  width: 100%;
  height: 7em;
  background-color: white;
  color: white ;
}

#menu{
  background-color: white;
  padding: 1em;
}

/* tiny devices - when navbar collapsed */
@media (min-width: 0px) and (max-width: 400px) {
  .AppNavBarLogo{
    width:3em;
  }
  
  .navbar
  {
    padding: 0.5em !important;
  } 

  .navbar-brand
  {
    margin: 0px !important;
    padding: 0px !important;
  } 


  .LogoBrand
  {
    margin: 0px !important;
    padding: 0px !important;
  } 


  .AppTitle-SubHeader{
    font-size: 0.5em !important;
  }
}

/* smaller devices - when navbar collapsed */
@media (min-width: 400px) and (max-width: 700px) {
  .AppNavBarLogo{
    width:3.2em;
  }
  
  .navbar
  {
    padding: 1.5em !important;
  } 

  .navbar-brand{
    margin-right: 0em;
    padding: 0em;
  }
  .AppTitle-SubHeader{
    font-size: 0.6em !important;
  }

  .MenuOption{
    margin: 0 0.5em 0 0.5em;
    white-space: nowrap;
  }
}

/* larger devices devices */
@media (min-width: 700px) {
  .AppNavBarLogo{
    width:3.8em;
  }

  
  .navbar
  {
    padding: 1em !important;
  } 
  
  .navbar-brand{
    padding: 0em;
  }
  .AppTitle-SubHeader{
    font-weight: normal !important;
  }

  .MenuOption{
    margin: 0 2.5em 0 2.5em;
    white-space: nowrap;
  }

  .MenuOptions{
    display: flex;
  }
  
}

.AppTitle{
  color: #2f2f2f;
}

.AppTitle-AppName{
  /* font-weight: 700; */
}

.navbar-light{
  color: white !important;
}

.navbar-nav{
  align-items: flex-end;
}

